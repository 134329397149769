import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Collapsible from '../collapsible/Collapsible';

import styles from './overlayReferenceSubmit.module.scss';

import { spacesApi } from '../../api';

import classNames from 'classnames';
import * as Cookies from 'js-cookie';
import SVG from 'react-inlinesvg';
import circleMinus from '../../assets/icons/circle-minus-light.svg';
import circlePlus from '../../assets/icons/circle-plus-light.svg';
import { router } from '../../router';
import Button from '../button/Button';
import Checkbox from '../form/checkbox/Checkbox';
import Input from '../form/input/Input';
import Select from '../form/select/Select';
import Textarea from '../form/textarea/Textarea';
import Headline from '../headline/Headline';
import LoginForm from '../login-form/LoginForm';
import Spinner from '../spinner/Spinner';
import { ReferenceSubmitFields } from './fields/ReferenceSubmitFields';
import jwtDecode from "jwt-decode";

const OverlayReferenceSubmitPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const locale = localStorage.getItem('i18nextLng');
  const currentResults = useSelector((state) => state.searchlog);
  const currentUserToken = useSelector((state) => state.user.token);
  const [decodedToken, setDecodedToken] = useState(null);
  const [fields, setFields] = useState(ReferenceSubmitFields);
  const [productOptions, setProductOptions] = useState([]);
  const products = useSelector((state) => state.filter.products);
  const [formData, setFormData] = useState(new FormData());

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      formData.append('assets[]', file, file.name);
      setFormData(formData);
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [...prevState, { id: e.target.result, src: e.target.result }]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop,
  });
  const [images, setImages] = useState([]);

  const validateSelect = (e) => {
    const index = e.target.name.split('_').pop();
    const name = e.target.name.split('_')[0];
    const data = JSON.parse(JSON.stringify(fields));
    const elementIndex = data['systems'][index].findIndex((element) => element.name === name);
    data['systems'][index][elementIndex].value = e.target.value;
    if (data['systems'][index][elementIndex].validation) {
      data['systems'][index][elementIndex].valid = e.target.value.match(
        data['systems'][index][elementIndex].validation
      );
    }

    setFields(data);
  };

  const setIsValid = (value, validation) => {
    return value.match(validation);
  };

  const validateField = (e) => {
    const data = JSON.parse(JSON.stringify(fields));
    let type = e.target.getAttribute('type');

    if (!type) {
      type = e.target.tagName.toLowerCase();
    }

    if (type === 'checkbox') {
      data[e.target.name].value = e.target.checked;
    } else {
      data[e.target.name].value = e.target.value;
    }

    if (data[e.target.name].validation) {
      data[e.target.name].valid = setIsValid(
        type === 'checkbox' ? e.target.checked : e.target.value,
        data[e.target.name].validation
      );
    }

    setFields(data);
  };

  const addSystem = () => {
    const data = JSON.parse(JSON.stringify(fields));
    data['systems'].push([
      {
        name: 'product',
        value: '',
        valid: true,
        validation: '^(?!\\s*$).+',
      },
      {
        name: 'system',
        value: '',
        valid: true,
        validation: '^(?!\\s*$).+',
      },
    ]);

    setFields(data);
  };

  /**
   * Removes a system
   *
   * @param index
   */
  const removeSystem = (index) => {
    const data = JSON.parse(JSON.stringify(fields));

    data['systems'].splice(index, 1);

    setFields(data);
  };

  /**
   * Submit the form
   */
  const submitForm = () => {
    let invalid = false;
    const data = JSON.parse(JSON.stringify(fields));
    Object.keys(data).forEach((key) => {
      if (key !== 'systems') {
        if (data[key].validation) {
          data[key].valid = setIsValid(fields[key].value, data[key].validation);
          if (!invalid && !data[key].valid) {
            invalid = key;
          }
        }
      }
    });

    setFields(data);

    if (invalid) {
      var element = document.querySelector("[name='" + invalid + "']");
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    setLoading(true);

    Object.keys(fields).forEach((key) => {
      if (key === 'systems') {
        fields[key].forEach((elements, index) => {
          elements.forEach((element) => {
            if(element.type === 'checkbox') {
              formData.append(element.name + '_' + index, element.value ? t('submitReference.yes') : t('submitReference.no'));
            } else {
              formData.append(element.name + '_' + index, element.value);
            }
          });
        });
      } else {
        formData.append(key, fields[key].value);
      }
    });
    formData.append('posterName', decodedToken.firstName + ' ' +  decodedToken.lastName)
    formData.append('posterEmail', decodedToken.email)


    const headers = {
      Authorization: `Bearer ${Cookies.get('token')}`,
      'Content-Type': 'multipart/form-data',
    };

    spacesApi
      .post(`/spaces/references/add`, formData, { headers })
      .then((response) => {
        setLoading(false);
        setSubmitted(true)
        // router.navigate(`/`);
      })
      .catch((err) => console.log(err))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    const productOptionsArr = [{ label: '', value: 0, systems: [{ label: '', value: 0 }] }];
    products.forEach((product) => {
      const systems = [{ label: '', value: 0 }];
      product.filterGroups.forEach((filterGroup) => {
        const options = [];
        filterGroup.filter.forEach((item) => options.push({ label: item.name, item: product.id }));
        systems.push({ label: filterGroup.name, type: 'optgroup', options: options });
      });

      productOptionsArr.push({ label: product.name, value: product.id, systems: systems });
    });
    setProductOptions(productOptionsArr);
  }, [products]);


  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    setDecodedToken(jwtDecode(currentUserToken));
  }, [currentUserToken]);

  return (
    <div
      className={classNames(styles.referenceSubmit, {
        [styles.unauthenticated]: null === currentUserToken,
      })}
    >
      {null === currentUserToken && <LoginForm />}
      {currentUserToken && decodedToken && (
        <>
          {loading && <Spinner />}
          <Headline size='h1'>{submitted ? t('submitReference.thankYou') : t('submitReference.headline')}</Headline>

          {submitted && (
              <p className={styles.thankYou}>
                {t('submitReference.thankYouMsg')}
              </p>
          )}

          {null !== currentResults && !submitted && (
            <div className={styles.form}>
              <div className={styles.formSection}>
                <div className={classNames(styles.formElement, styles.right10)}>
                  <Input
                      type={'text'}
                      label={t('submitReference.name')}
                      name={'posterName'}
                      mandatory={true}
                      value={decodedToken.firstName + ' ' +  decodedToken.lastName}
                      disabled={true}
                  />
                </div>
                <div className={styles.formElement}>
                  <Input
                      type={'email'}
                      label={t('submitReference.email')}
                      name={'posterEmail'}
                      mandatory={true}
                      value={decodedToken.email}
                      disabled={true}
                  />
                </div>
              </div>
              <div className={styles.formSection}>
                <div className={classNames(styles.formElement, styles.fullWidth)}>
                  <Input
                    type={fields.title.type}
                    label={t('submitReference.title')}
                    name={'title'}
                    mandatory={true}
                    value={fields.name.value}
                    invalid={!fields.title.valid}
                    onChange={validateField}
                  />
                </div>
              </div>

              <Collapsible defaultOpen={true} elementStyle={'grey-10'}>
                <span className={styles.formHeadline}>{t('submitReference.contactInfo')}</span>
                <div>
                  <div className={styles.formSection}>
                    <div className={classNames(styles.formElement, styles.right10)}>
                      <Input
                        type={fields.name.type}
                        label={t('submitReference.companyOrName')}
                        name={'name'}
                        mandatory={true}
                        value={fields.name.value}
                        invalid={!fields.name.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={styles.formElement}>
                      <Input
                        type={fields.street.type}
                        label={t('submitReference.street')}
                        name={'street'}
                        mandatory={true}
                        value={fields.street.value}
                        invalid={!fields.street.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.right10)}>
                      <Input
                        type={fields.contact.type}
                        label={t('submitReference.contact')}
                        name={'contact'}
                        mandatory={true}
                        value={fields.contact.value}
                        invalid={!fields.contact.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.zip)}>
                      <Input
                        type={fields.zip.type}
                        label={t('submitReference.zip')}
                        name={'zip'}
                        value={fields.zip.value}
                        invalid={!fields.zip.valid}
                        mandatory={true}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.city)}>
                      <Input
                        type={fields.city.type}
                        label={t('submitReference.city')}
                        name={'city'}
                        value={fields.city.value}
                        invalid={!fields.city.valid}
                        mandatory={true}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.right10)}>
                      <Input
                        type={fields.phone.type}
                        label={t('submitReference.phone')}
                        name={'phone'}
                        value={fields.phone.value}
                        invalid={!fields.phone.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={styles.formElement}>
                      <Input
                        type={fields.email.type}
                        label={t('submitReference.email')}
                        name={'email'}
                        value={fields.email.value}
                        invalid={!fields.email.valid}
                        onChange={validateField}
                      />
                    </div>
                    <span className={classNames(styles.formHeadline, styles.noPadding)}>
                      {t('submitReference.objectIs')} <span className={styles.mandatory}>*</span>
                    </span>
                    <div className={classNames(styles.formElement, styles.checkbox)}>
                      <Checkbox
                        label={t('submitReference.newlyBuilt')}
                        name={'newlyBuilt'}
                        checked={fields.newlyBuilt.value}
                        onClick={validateField}
                      />
                    </div>
                    <div className={styles.formElement}>
                      <Checkbox
                        label={t('submitReference.renovationOrExtension')}
                        name={'renovationOrExtension'}
                        checked={fields.renovationOrExtension.value}
                        onClick={validateField}
                      />
                    </div>
                  </div>
                  <div className={styles.formSection}>
                    <div className={classNames(styles.formElement, styles.small)}>
                      <Input
                        type={fields.orderNumber1.type}
                        label={t('submitReference.orderNumbers')}
                        name={'orderNumber1'}
                        value={fields.orderNumber1.value}
                        mandatory={true}
                        invalid={!fields.orderNumber1.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.small)}>
                      <Input
                        type={fields.orderNumber2.type}
                        label={''}
                        name={'orderNumber2'}
                        value={fields.orderNumber2.value}
                        invalid={!fields.orderNumber2.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.small)}>
                      <Input
                        type={fields.orderNumber3.type}
                        label={''}
                        name={'orderNumber3'}
                        value={fields.orderNumber3.value}
                        invalid={!fields.orderNumber3.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.small)}>
                      <Input
                        type={fields.orderNumber4.type}
                        label={''}
                        name={'orderNumber4'}
                        value={fields.orderNumber4.value}
                        invalid={!fields.orderNumber4.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.small)}>
                      <Input
                        type={fields.orderNumber5.type}
                        label={''}
                        name={'orderNumber5'}
                        value={fields.orderNumber5.value}
                        invalid={!fields.orderNumber5.valid}
                        onChange={validateField}
                      />
                    </div>
                  </div>

                  <div className={styles.formSection}>
                    <div className={styles.formElement}>
                      <Checkbox
                        label={t('submitReference.differentAddress')}
                        name={'differentAddress'}
                        checked={fields.differentAddress.value}
                        onClick={validateField}
                      />
                    </div>

                    {fields.differentAddress.value && (
                      <>
                        <div className={classNames(styles.formElement, styles.right10)}>
                          <Input
                            type={fields.differentStreet.type}
                            label={t('submitReference.street')}
                            name={'differentStreet'}
                            value={fields.differentStreet.value}
                            invalid={!fields.differentStreet.valid}
                            onChange={validateField}
                          />
                        </div>
                        <div className={classNames(styles.formElement, styles.zip)}>
                          <Input
                            type={fields.differentZip.type}
                            label={t('submitReference.zip')}
                            name={'differentZip'}
                            value={fields.differentZip.value}
                            invalid={!fields.differentZip.valid}
                            onChange={validateField}
                          />
                        </div>
                        <div className={classNames(styles.formElement, styles.city)}>
                          <Input
                            type={fields.differentCity.type}
                            label={t('submitReference.city')}
                            name={'differentCity'}
                            value={fields.differentCity.value}
                            invalid={!fields.differentCity.valid}
                            onChange={validateField}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Collapsible>

              <Collapsible elementStyle={'grey-10'}>
                <span className={styles.formHeadline}>
                  {t('submitReference.contactArchitectInfo')}
                </span>

                <div className={styles.formSection}>
                  <div className={classNames(styles.formElement, styles.right10)}>
                    <Input
                      type={fields.architectName.type}
                      label={t('submitReference.companyOrName')}
                      name={'architectName'}
                      value={fields.architectName.value}
                      invalid={!fields.architectName.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={styles.formElement}>
                    <Input
                      type={fields.architectStreet.type}
                      label={t('submitReference.street')}
                      name={'architectStreet'}
                      value={fields.architectStreet.value}
                      invalid={!fields.architectStreet.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.right10)}>
                    <Input
                      type={fields.architectContact.type}
                      label={t('submitReference.contact')}
                      name={'architectContact'}
                      value={fields.architectContact.value}
                      invalid={!fields.architectContact.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.zip)}>
                    <Input
                      type={fields.architectZip.type}
                      label={t('submitReference.zip')}
                      name={'architectZip'}
                      value={fields.architectZip.value}
                      invalid={!fields.architectZip.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.city)}>
                    <Input
                      type={fields.architectCity.type}
                      label={t('submitReference.city')}
                      name={'architectCity'}
                      value={fields.architectCity.value}
                      invalid={!fields.architectCity.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.right10)}>
                    <Input
                      type={fields.architectPhone.type}
                      label={t('submitReference.phone')}
                      name={'architectPhone'}
                      value={fields.architectPhone.value}
                      invalid={!fields.architectPhone.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={styles.formElement}>
                    <Input
                      type={fields.architectEmail.type}
                      label={t('submitReference.email')}
                      name={'architectEmail'}
                      value={fields.architectEmail.value}
                      invalid={!fields.architectEmail.valid}
                      onChange={validateField}
                    />
                  </div>
                </div>
              </Collapsible>

              <Collapsible elementStyle={'grey-10'}>
                <span className={styles.formHeadline}>
                  {t('submitReference.establishContact')}
                  <span className={styles.mandatory}>*</span>
                </span>

                <div className={styles.formSection}>
                  <div className={classNames(styles.formElement, styles.checkbox)}>
                    <Checkbox
                      label={t('submitReference.directly')}
                      name={'directly'}
                      checked={fields.directly.value}
                      onClick={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.checkbox)}>
                    <Checkbox
                      label={t('submitReference.qualityPartner')}
                      name={'qualityPartner'}
                      checked={fields.qualityPartner.value}
                      onClick={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.checkbox)}>
                    <Checkbox
                      label={t('submitReference.architect')}
                      name={'architect'}
                      checked={fields.architect.value}
                      onClick={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.checkbox)}>
                    <Checkbox
                      label={t('submitReference.sales')}
                      name={'sales'}
                      checked={fields.sales.value}
                      onClick={validateField}
                    />
                  </div>
                  <div className={classNames(styles.formElement, styles.right10)}>
                    <Input
                      type={fields.establishContact.type}
                      label={t('submitReference.contact')}
                      name={'establishContact'}
                      mandatory={true}
                      value={fields.establishContact.value}
                      invalid={!fields.establishContact.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={styles.formElement}>
                    <Input
                      type={fields.establishPhone.type}
                      label={t('submitReference.phone')}
                      name={'establishPhone'}
                      value={fields.establishPhone.value}
                      invalid={!fields.establishPhone.valid}
                      onChange={validateField}
                    />
                  </div>
                  <div className={styles.formElement}>
                    <Input
                      type={fields.establishEmail.type}
                      label={t('submitReference.email')}
                      name={'establishEmail'}
                      value={fields.establishEmail.value}
                      invalid={!fields.establishEmail.valid}
                      onChange={validateField}
                    />
                  </div>
                </div>
              </Collapsible>

              <Collapsible elementStyle={'grey-10'}>
                <div>
                  <span className={styles.formHeadline}>{t('submitReference.slSystem')}</span>
                  <span className={styles.formSubline}>{t('submitReference.slSystemSubline')}</span>
                </div>

                <div className={styles.formSection}>
                  {fields.systems.map((elements, index) => (
                    <div
                      key={'el' + index}
                      className={classNames(styles.formElement, styles.fullWidth)}
                    >
                      {elements.map((element, elIndex) => {
                        let systems = {
                          systems: [{ label: '', value: 0 }],
                        };
                        if (element.name === 'system') {
                          const product = elements.find((el) => el.name === 'product');
                          if (product.value) {
                            systems = productOptions.find(
                              (option) => option.label === product.value
                            );
                          }
                        }

                        return (
                          <div
                            key={index + 'elem' + elIndex}
                            className={classNames(styles.formElement, styles.system)}
                          >
                            <Select
                              label={t('submitReference.' + element.name)}
                              name={element.name + '_' + index}
                              selected={element.value}
                              mandatory={true}
                              options={
                                element.name === 'product' ? productOptions : systems.systems
                              }
                              onChange={validateSelect}
                            />
                          </div>
                        );
                      })}
                      <div key={index} className={classNames(styles.formElement, styles.icon)}>
                        {index + 1 === fields.systems.length && (
                          <SVG onClick={addSystem} src={circlePlus} />
                        )}

                        {index + 1 < fields.systems.length && (
                          <SVG onClick={(e) => removeSystem(index)} src={circleMinus} />
                        )}
                      </div>
                    </div>
                  ))}

                  <div className={classNames(styles.formElement, styles.fullWidth)}>
                    <Textarea
                      label={t('submitReference.specialFeatures')}
                      subLabel={t('submitReference.specialFeaturesSub')}
                      name={'specialFeatures'}
                      value={fields.specialFeatures.value}
                      invalid={!fields.specialFeatures.valid}
                      onChange={validateField}
                    />
                  </div>
                </div>
              </Collapsible>

              <Collapsible elementStyle={'grey-10'}>
                <span className={styles.formHeadline}>{t('submitReference.keyData')}</span>

                <div>
                  <div className={styles.formSection}>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.year.type}
                        design={'side'}
                        label={t('submitReference.year')}
                        name={'year'}
                        value={fields.year.value}
                        invalid={!fields.year.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.yearPatio.type}
                        design={'side'}
                        label={t('submitReference.yearPatio')}
                        name={'yearPatio'}
                        value={fields.yearPatio.value}
                        invalid={!fields.yearPatio.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.area.type}
                        design={'side'}
                        label={t('submitReference.area')}
                        name={'area'}
                        value={fields.area.value}
                        invalid={!fields.area.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.shading.type}
                        design={'side'}
                        label={t('submitReference.shading')}
                        name={'shading'}
                        value={fields.shading.value}
                        invalid={!fields.shading.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.aeration.type}
                        design={'side'}
                        label={t('submitReference.aeration')}
                        name={'aeration'}
                        value={fields.aeration.value}
                        invalid={!fields.aeration.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.opening.type}
                        design={'side'}
                        label={t('submitReference.opening')}
                        name={'opening'}
                        value={fields.opening.value}
                        invalid={!fields.opening.valid}
                        onChange={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Input
                        type={fields.other.type}
                        design={'side'}
                        label={t('submitReference.other')}
                        name={'other'}
                        value={fields.other.value}
                        invalid={!fields.other.valid}
                        onChange={validateField}
                      />
                    </div>
                  </div>

                  <span className={styles.formHeadline}>{t('submitReference.suitable')}</span>

                  <div className={styles.formSection}>
                    <div className={classNames(styles.formElement, styles.checkbox, styles.wide)}>
                      <Checkbox
                        label={t('submitReference.interestingArchitecture')}
                        name={'interestingArchitecture'}
                        checked={fields.interestingArchitecture.value}
                        onClick={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.checkbox)}>
                      <Checkbox
                        label={t('submitReference.specialUse')}
                        name={'specialUse'}
                        checked={fields.specialUse.value}
                        onClick={validateField}
                      />
                    </div>
                    <div className={classNames(styles.formElement, styles.checkbox)}>
                      <Checkbox
                        label={t('submitReference.otherUse')}
                        name={'otherUse'}
                        checked={fields.otherUse.value}
                        onClick={validateField}
                      />
                    </div>
                  </div>

                  <div className={styles.formSection}>
                    <div className={classNames(styles.formElement, styles.fullWidth)}>
                      <Textarea
                        label={t('submitReference.additionalInfo')}
                        subLabel={t('submitReference.additionalInfoSub')}
                        name={'additionalInfo'}
                        value={fields.additionalInfo.value}
                        invalid={!fields.additionalInfo.valid}
                        onChange={validateField}
                      />
                    </div>
                  </div>
                </div>
              </Collapsible>

              <span className={classNames(styles.formHeadline, styles['grey-10'])}>
                {t('submitReference.photos')}
                <span className={styles.mandatory}>*</span>
              </span>

              <div className={styles.formSection}>
                <div className={classNames(styles.formElement, styles.fullWidth)}>
                  <div className={styles.dropzoneWrapper}>
                    <div {...getRootProps({ className: styles.dropzone })}>
                      <input className='input-zone' {...getInputProps()} />
                      <div className={styles.textCenter}>
                        {isDragActive ? (
                          <p className='dropzone-content'>{t('submitReference.dropzoneDrop')}</p>
                        ) : (
                          <p className={styles.dropzoneContent}>{t('submitReference.dropzone')}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.imageGrid}>
                    {images.map((image) => (
                      <div className='file-item'>
                        <img alt={`img - ${image.id}`} src={image.src} className='file-img' />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <Button look='primary-red' size='large' onClick={() => submitForm()}>
                {t('submitReference.submit')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withTranslation('translations')(OverlayReferenceSubmitPage);
