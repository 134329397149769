import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../redux/actions';

import styles from './overlaySubmitedReferences.module.scss';

import { spacesApi } from '../../api';

import { PDFDownloadLink } from '@react-pdf/renderer';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import angleDownIcon from '../../assets/icons/angle-down.svg';
import angleLeftIcon from '../../assets/icons/angle-left.svg';
import angleUpIcon from '../../assets/icons/angle-up.svg';
import pdflIcon from '../../assets/icons/file-pdf-light.svg';
import pencilIcon from '../../assets/icons/pencil-thin.svg';
import Select from '../form/select/Select';
import Headline from '../headline/Headline';
import LoginForm from '../login-form/LoginForm';
import Modal from '../modal/Modal';
import ReferenceDocument from '../pdf-document/ReferenceDocument';
import Spinner from '../spinner/Spinner';

const OverlayReferenceSubmitPage = () => {
  const [t] = useTranslation('translations');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const currentResults = useSelector((state) => state.submitedReferences);
  const [references, setReferences] = useState(currentResults);
  const [selectedReference, setSelectedReference] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const currentUserToken = useSelector((state) => state.user.token);
  const [decodedToken, setDecodedToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const statuses = [
    { label: t('submitedReferences.open'), id: 0 },
    { label: t('submitedReferences.progress'), id: 1 },
    { label: t('submitedReferences.rejected'), id: 2 },
    { label: t('submitedReferences.done'), id: 3 },
  ];
  const statusesFilter = [
    { label: t('submitedReferences.all'), id: '' },
    { label: t('submitedReferences.open'), id: 0 },
    { label: t('submitedReferences.progress'), id: 1 },
    { label: t('submitedReferences.rejected'), id: 2 },
    { label: t('submitedReferences.done'), id: 3 },
  ];

  const format = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${date}.${month}.${year}`;
  };

  const status = (intStatus) => {
    let string = '';

    switch (isNaN(parseInt(intStatus)) ? intStatus :  parseInt(intStatus)) {
      case '':
        string = t('submitedReferences.all');
        break;
      case 1:
        string = t('submitedReferences.progress');
        break;
      case 2:
        string = t('submitedReferences.rejected');
        break;
      case 3:
        string = t('submitedReferences.done');
        break;
      default:
        string = t('submitedReferences.open');
        break;
    };

    return string;
  };

  const nextPage = () => {
    const { page } = references;

    if (page === references.pageCount) {
      return;
    }

    setReferences({
      ...references,
      page: page + 1,
    });
  };

  const previousPage = () => {
    const { page } = references;

    if (page === 1) {
      return;
    }

    setReferences({
      ...references,
      page: page - 1,
    });
  };

  const loadSubmitedReferences = async () => {
    setLoading(true);

    const headers = {
      Authorization: 'Bearer ' + currentUserToken,
    };

    spacesApi
      .get('spaces/references/fetch/submited', {
        headers,
        params: {
          page: references.page,
          direction: references.direction,
          sortBy: references.sortBy,
          status: references.status,
        },
      })
      .then((res) => {
        setReferences(res.data);
        dispatch(actions.referenceActions.setSubmitedReferences(res.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  const edit = (index) => {
    setSelectedReference(references.references[index]);
    setSelectedIndex(index);
    setShowModal(true);
  };
  const exportPDF = (index) => {};

  const setStatus = (e) => {
    references.references[selectedIndex].status = e.target.value;
    setLoading(true);
    const headers = {
      Authorization: 'Bearer ' + currentUserToken,
      'Content-Type': 'application/json',
    };

    spacesApi
      .post(
        'spaces/references/status',
        JSON.stringify({
          id: references.references[selectedIndex].id,
          status: references.references[selectedIndex].status,
        }),
        { headers }
      )
      .then((res) => {
        setReferences({
          ...references,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  const sortBy = (column) => {
    const { sortBy, direction } = references;
    let newDirection = direction;
    let newSortBy = sortBy;

    if (sortBy === column) {
      newDirection = direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      newSortBy = column;
      newDirection = 'DESC';
    }

    setReferences({
      ...references,
      sortBy: newSortBy,
      direction: newDirection,
    });
  };

  const filterByStatus = (e) => {
    setReferences({
      ...references,
      status: e.target.value,
    });
  };

  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    loadSubmitedReferences();

    setDecodedToken(jwtDecode(currentUserToken));
  }, [currentUserToken]);

  useEffect(() => {
    if (null === currentUserToken) {
      return;
    }

    loadSubmitedReferences();
  }, [references.page, references.sortBy, references.direction, references.status]);

  return (
    <div
      className={classNames(styles.referenceSubmit, {
        [styles.unauthenticated]: null === currentUserToken,
      })}
    >
      {null === currentUserToken && <LoginForm />}
      {currentUserToken && (
        <>
          <Headline size='h1'>{t('submitedReferences.headline')}</Headline>

          {null !== currentResults.references && (
            <>
              <div className={styles.results}>
                <div className={styles.filter}>
                  <Select
                    label={t('submitedReferences.statusFilter')}
                    name={'status'}
                    selected={status(references.status)}
                    options={statusesFilter}
                    onChange={filterByStatus}
                  />
                </div>

                <ul>
                  <li
                    className={classNames(styles.header, styles.left, styles.clickable)}
                    onClick={() => sortBy('title')}
                  >
                    {t('submitedReferences.projectTitle')}
                    <span className={styles.direction}>
                      <SVG
                        className={
                          references.sortBy === 'title' && references.direction === 'ASC'
                            ? styles.selected
                            : ''
                        }
                        src={angleUpIcon}
                      />
                      <SVG
                        className={
                          references.sortBy === 'title' && references.direction === 'DESC'
                            ? styles.selected
                            : ''
                        }
                        src={angleDownIcon}
                      />
                    </span>
                  </li>
                  <li className={styles.header}>{t('submitedReferences.product')}</li>
                  <li className={styles.header}>{t('submitedReferences.system')}</li>
                  <li
                    className={classNames(styles.header, styles.clickable)}
                    onClick={() => sortBy('establishContact')}
                  >
                    {t('submitedReferences.adm')}
                    <span className={styles.direction}>
                      <SVG
                        className={
                          references.sortBy === 'establishContact' && references.direction === 'ASC'
                            ? styles.selected
                            : ''
                        }
                        src={angleUpIcon}
                      />
                      <SVG
                        className={
                          references.sortBy === 'establishContact' &&
                          references.direction === 'DESC'
                            ? styles.selected
                            : ''
                        }
                        src={angleDownIcon}
                      />
                    </span>
                  </li>
                  <li
                    className={classNames(styles.header, styles.clickable)}
                    onClick={() => sortBy('createdAt')}
                  >
                    {t('submitedReferences.date')}
                    <span className={styles.direction}>
                      <SVG
                        className={
                          references.sortBy === 'createdAt' && references.direction === 'ASC'
                            ? styles.selected
                            : ''
                        }
                        src={angleUpIcon}
                      />
                      <SVG
                        className={
                          references.sortBy === 'createdAt' && references.direction === 'DESC'
                            ? styles.selected
                            : ''
                        }
                        src={angleDownIcon}
                      />
                    </span>
                  </li>
                  <li
                    className={classNames(styles.header, styles.status, styles.clickable)}
                    onClick={() => sortBy('status')}
                  >
                    {t('submitedReferences.status')}
                    <span className={styles.direction}>
                      <SVG
                        className={
                          references.sortBy === 'status' && references.direction === 'ASC'
                            ? styles.selected
                            : ''
                        }
                        src={angleUpIcon}
                      />
                      <SVG
                        className={
                          references.sortBy === 'status' && references.direction === 'DESC'
                            ? styles.selected
                            : ''
                        }
                        src={angleDownIcon}
                      />
                    </span>
                  </li>
                  <li className={styles.header}>{t('submitedReferences.name')}</li>
                  <li className={styles.header}></li>
                  {currentResults.references.map((result, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li className={classNames(styles.item, styles.left)}>{result.title}</li>
                        <li className={styles.item}>
                          {JSON.parse(result.productsSystems)[0].product}
                        </li>
                        <li className={styles.item}>
                          {JSON.parse(result.productsSystems)[0].system}
                        </li>
                        <li className={styles.item}>{result.establishContact}</li>
                        <li className={styles.item}>{format(new Date(result.createdAt))}</li>
                        <li className={classNames(styles.item, styles.status)}>
                          {status(result.status)}
                        </li>
                        <li className={classNames(styles.item, styles.status)}>
                          {result.posterName}
                        </li>
                        <li className={classNames(styles.item, styles.edit)}>
                          <SVG
                            onClick={() => edit(index)}
                            src={pencilIcon}
                            className={styles.svg}
                          />

                          <PDFDownloadLink
                            document={<ReferenceDocument data={result} />}
                            fileName={`solarlux-submitted-reference-${Math.floor(
                              new Date().getTime() / 1000
                            )}.pdf`}
                            className={styles.pdfDownloadLink}
                          >
                            {({ blob, url, loading, error }) => {
                              return (
                                <SVG
                                  onClick={() => exportPDF(index)}
                                  src={pdflIcon}
                                  className={styles.svg}
                                />
                              );
                            }}
                          </PDFDownloadLink>
                        </li>
                      </React.Fragment>
                    );
                  })}
                </ul>
                {currentResults.pageCount > 1 && (
                  <>
                    <div className={styles.pagination}>
                      <div className={styles.iconLeft}>
                        <SVG onClick={previousPage} src={angleLeftIcon} />
                      </div>
                      <div className={styles.page}>
                        {references.page} / {references.pageCount}
                      </div>
                      <div className={styles.iconRight}>
                        <SVG onClick={nextPage} src={angleLeftIcon} />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Modal
                onClose={() => setShowModal(false)}
                showModal={showModal}
                title={t('submitedReferences.editModal')}
                style={{ width: '50%', maxHeight: '90%', overflowY: 'scroll' }}
              >
                {Object.keys(selectedReference).length > 0 && (
                  <>
                    <div className={styles.statusSelect}>
                      <Select
                        label={t('submitedReferences.status')}
                        name={'status'}
                        selected={status(selectedReference.status)}
                        options={statuses}
                        onChange={setStatus}
                      />
                    </div>

                    <div className={classNames(styles.section, styles.colored)}>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitedReferences.title')}</span>
                        <span className={styles.value}>{selectedReference.title}</span>
                      </div>
                      <div className={classNames(styles.element, styles.right)}>
                        <span className={styles.label}>{t('submitedReferences.adm')}</span>
                        <span className={styles.value}>{selectedReference.establishContact}</span>
                      </div>
                    </div>

                    {JSON.parse(selectedReference.productsSystems).map((system, index) => (
                      <div key={index} className={styles.section}>
                        <div className={styles.element}>
                          <span className={styles.label}>{t('submitReference.product')}</span>
                          <span className={styles.value}>{system.product}</span>
                        </div>
                        <div className={classNames(styles.element)}>
                          <span className={styles.label}>{t('submitReference.system')}</span>
                          <span className={styles.value}>{system.system}</span>
                        </div>

                        {index + 1 === JSON.parse(selectedReference.productsSystems).length && (
                          <div className={classNames(styles.element, styles.right)}>
                            <span className={styles.label}>
                              {t('submitedReferences.dateModal')}
                            </span>
                            <span className={styles.value}>
                              {format(new Date(selectedReference.createdAt))}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitReference.contactInfo')}</span>
                        <span className={styles.value}>{selectedReference.name}</span>
                        <span className={styles.value}>{selectedReference.street}</span>
                        <span
                          className={styles.value}
                        >{`${selectedReference.zip} ${selectedReference.city}`}</span>
                      </div>
                      <div className={styles.element}>
                        <span className={classNames(styles.label, styles.noValue)}>{''}</span>
                        <span className={styles.value}>{selectedReference.contact}</span>
                        <span className={styles.value}>
                          <a href={`mailto:${selectedReference.email}`}>
                            {selectedReference.email}
                          </a>
                        </span>
                        <span className={styles.value}>{selectedReference.phone}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitReference.objectIs')}</span>
                        {selectedReference.newlyBuilt && (
                          <span className={styles.value}>{t('submitReference.newlyBuilt')}</span>
                        )}
                        {selectedReference.renovationOrExtension && (
                          <span className={styles.value}>
                            {t('submitReference.renovationOrExtension')}
                          </span>
                        )}
                      </div>
                    </div>

                    {selectedReference.differentAddress && (
                      <div className={styles.section}>
                        <div className={styles.element}>
                          <span className={styles.label}>
                            {t('submitReference.differentAddress')}
                          </span>
                          <span className={styles.value}>{selectedReference.differentStreet}</span>
                          <span
                            className={styles.value}
                          >{`${selectedReference.differentZip} ${selectedReference.differentCity}`}</span>
                        </div>
                      </div>
                    )}

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>
                          {t('submitReference.contactArchitectInfo')}
                        </span>
                        <span className={styles.value}>{selectedReference.architectName}</span>
                        <span className={styles.value}>{selectedReference.architectStreet}</span>
                        <span
                          className={styles.value}
                        >{`${selectedReference.architectZip} ${selectedReference.architectCity}`}</span>
                      </div>
                      <div className={styles.element}>
                        <span className={classNames(styles.label, styles.noValue)}>{''}</span>
                        <span className={styles.value}>{selectedReference.architectContact}</span>
                        <span className={styles.value}>
                          <a href={`mailto:${selectedReference.architectEmail}`}>
                            {selectedReference.architectEmail}
                          </a>
                        </span>
                        <span className={styles.value}>{selectedReference.architectPhone}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>
                          {t('submitReference.establishContact')}
                        </span>
                        {selectedReference.directly && (
                          <span className={styles.value}>{t('submitReference.directly')}</span>
                        )}
                        {selectedReference.qualityPartner && (
                          <span className={styles.value}>
                            {t('submitReference.qualityPartner')}
                          </span>
                        )}
                        {selectedReference.architect && (
                          <span className={styles.value}>{t('submitReference.architect')}</span>
                        )}
                        {selectedReference.sales && (
                          <span className={styles.value}>{t('submitReference.sales')}</span>
                        )}
                      </div>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitReference.contact')}</span>
                        <span className={styles.value}>{selectedReference.establishContact}</span>
                        <span className={styles.value}>
                          <a href={`mailto:${selectedReference.establishEmail}`}>
                            {selectedReference.establishEmail}
                          </a>
                        </span>
                        <span className={styles.value}>{selectedReference.establishPhone}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.fullWidth)}>
                        <span className={styles.label}>{t('submitReference.specialFeatures')}</span>
                        <span
                          className={styles.value}
                          dangerouslySetInnerHTML={{
                            __html: selectedReference.specialFeatures?.replace(
                              /(\r\n|\r|\n)/g,
                              '<br>'
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.keyData')}</span>
                        <span className={styles.label}>{t('submitReference.year')}</span>
                        <span className={styles.value}>{selectedReference.year}</span>
                      </div>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={classNames(styles.label, styles.noValue)}>{''}</span>
                        <span className={styles.label}>{t('submitReference.yearPatio')}</span>
                        <span className={styles.value}>{selectedReference.yearPatio}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.area')}</span>
                        <span className={styles.value}>{selectedReference.area}</span>
                      </div>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.shading')}</span>
                        <span className={styles.value}>{selectedReference.shading}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.aeration')}</span>
                        <span className={styles.value}>{selectedReference.aeration}</span>
                      </div>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.opening')}</span>
                        <span className={styles.value}>{selectedReference.opening}</span>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.half)}>
                        <span className={styles.label}>{t('submitReference.other')}</span>
                        <span className={styles.value}>{selectedReference.other}</span>
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitReference.suitable')}</span>
                        {selectedReference.interestingArchitecture && (
                          <span className={styles.value}>
                            {t('submitReference.interestingArchitecture')}
                          </span>
                        )}
                        {selectedReference.specialUse && (
                          <span className={styles.value}>{t('submitReference.specialUse')}</span>
                        )}
                        {selectedReference.otherUse && (
                          <span className={styles.value}>{t('submitReference.otherUse')}</span>
                        )}
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={classNames(styles.element, styles.fullWidth)}>
                        <span className={styles.label}>{t('submitReference.additionalInfo')}</span>
                        <span
                          className={styles.value}
                          dangerouslySetInnerHTML={{
                            __html: selectedReference.additionalInfo?.replace(
                              /(\r\n|\r|\n)/g,
                              '<br>'
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.element}>
                        <span className={styles.label}>{t('submitReference.orderNumbers')}</span>
                        <span className={styles.value}>{selectedReference.orderNumber1}</span>
                        <span className={styles.value}>{selectedReference.orderNumber2}</span>
                        <span className={styles.value}>{selectedReference.orderNumber3}</span>
                        <span className={styles.value}>{selectedReference.orderNumber4}</span>
                        <span className={styles.value}>{selectedReference.orderNumber5}</span>
                      </div>
                    </div>
                  </>
                )}
              </Modal>
            </>
          )}
        </>
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default withTranslation('translations')(OverlayReferenceSubmitPage);
